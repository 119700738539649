
.carousel .carousel-control{
    width: 50px;
}
.carousel .left.carousel-control{
    left: 0px;
}
.carousel .right.carousel-control{
    right: 0px;
}
.carousel .left.carousel-control .material-icons{
    left: 0;
    right: auto;
}
.carousel .right.carousel-control .material-icons{
    left: 0;
    right: auto;
}
.item .page-header .container{
    padding-top: 0;
}
.item .btn{
    margin-top: -20px;
    margin-bottom: 40px;
    color: black;
    font-weight: bold;
}
.home-slider-next{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    text-align: center;
}
.home-slider-next a{
    display: inline-block;
    padding: 0 15px;
    line-height: 1;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.home-slider-next a .material-icons{
    font-size: 60px;
    color: white;
    opacity: .8;
}
.home-slider-next a:hover .material-icons{
    opacity: 1;
}
.slider-title.text-white{
    color: white;
    padding: 10px 0px;
    line-height: 1.3;
    display: inline-block;
}
.slider-title.text-black{
    color: black;
    padding: 10px 0px;
    line-height: 1.3;
    display: inline-block;
}
.text-left.slider-text,
.text-right.slider-text{
    .text{
        display: block;
    }
}
.slider-text{
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0,0,0);
    color: white;
    font-style: italic;
    .text-sub{
        display: block;
        padding-bottom: 15px;
    }
    //font-weight: bold;
    .text{
        text-transform: uppercase;
        line-height: 1.4;
        //font-weight: bold;
        //line-height: 1.7em;
        display: inline-block;
        //margin: 0 17px;
        font-family: $title-font;
        //padding: 8px 16px;
        &.text-xs{
            font-size: 14px;
            @media (max-width: 1199px) {
                font-size: 13px;
            }
            @media (max-width: 991px) {
                font-size: 12px;
            }
            @media (max-width: 767px) {
                font-size: 11px;
            }
        }
        &.text-sm{
            font-size: 24px;
            @media (max-width: 1199px) {
                font-size: 22px;
            }
            @media (max-width: 991px) {
                font-size: 20px;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
        &.text-md{
            font-size: 32px;
            @media (max-width: 1199px) {
                font-size: 27px;
            }
            @media (max-width: 991px) {
                font-size: 22px;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        &.text-lg{
            font-size: 48px;
            @media (max-width: 1199px) {
                font-size: 40px;
            }
            @media (max-width: 991px) {
                font-size: 32px;
            }
            @media (max-width: 767px) {
                font-size: 24px;
            }
        }
        &.text-xl{
            font-size: 64px;
            @media (max-width: 1199px) {
                font-size: 52px;
            }
            @media (max-width: 991px) {
                font-size: 40px;
            }
            @media (max-width: 767px) {
                font-size: 32px;
            }
        }
    }
}
.carousel-text-position-top,
.carousel-text-position-middle,
.carousel-text-position-bottom{
    position: absolute;
    left: 0;
    right: 0;
}
.carousel-text-position-top{
    top: 100px;
}
.carousel-text-position-middle{
    top: 52%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10000000;
    @media (max-width: 767px) {}
}
.carousel-text-position-bottom{
    bottom: 5%;
}
.slider-with-intro-arrow .carousel-text-position-bottom{
    bottom: 55px;
}
.page-header{
    border-bottom: 5px solid $brand-dark;
}
.page-header.page-header-fs{
    min-height: 100vh;
    min-height: calc(100vh - 83px);
    height: auto;
    @media (max-width: 767px) {
        min-height: 60vh;
    }
}
.page-header.page-header-hs {
  min-height: 45vh;
  height: auto;
}
.page-header.page-header-25 {
  min-height: 30vh;
  height: auto;
}
.page-header.page-header-75 {
  min-height: 70vh;
  height: auto;
}