.page-header.page-header-fs{
    min-height: 100vh;
    height: auto;
}
.page-header.page-header-hs{
    min-height: 55vh;
    height: auto;
}
.page-header.page-header-25{
    min-height: 25vh;
    height: auto;
}
.page-header.page-header-75{
    min-height: 75vh;
    height: auto;
}
.page-header.page-header-xs{
    min-height: 20vh;
    height: auto;
}
.page-header.page-header-sm{
    min-height: 40vh;
    height: auto;
}
.page-header.page-header-md{
    min-height: 60vh;
    height: auto;
}
.page-header.page-header-lg{
    min-height: 80vh;
    height: auto;
}
.navbar{
    margin-bottom: 0;
    padding: 0;
    box-shadow: none;
    border-bottom: 5px solid $brand-dark;
    &.navbar-default {
        color: $brand-dark;
    }
    .navbar-brand {
        position: relative;
        height: auto;
        line-height: 1;
        padding: 12px 15px 8px;
        @media (min-width: 768px) and (max-width: 991px) {
            padding: 19px 5px 11px;
        }
        img{
            display: block;
            height: 58px;
            @media (min-width: 768px) and (max-width: 991px) {
                height: 48px;
            }
        }
    }
    .navbar-toggle{
        margin-top: 23px;
    }
    .navbar-nav{
        margin-top: 14px;
        li{
            a{
                font-size: 21px;
                font-size: 18px;
                text-transform: none;
                padding-top: 30px;
                padding-bottom: 5px;
                @media (min-width: 768px) and (max-width: 991px) {
                    font-size: 16px;
                }
                @media (max-width: 767px){
                    padding-top: 5px;
                }
            }
            a:hover{
                color: $brand-link;
            }
        }
        li.active{
            a:hover,
            a{
                color: $brand-link;
            }
        }
        .dropdown-menu li > a {
            color: $brand-dark;
            font-size: 15px;
            padding: 10px 20px;
            margin: 0 5px;
            border-radius: 2px;
            -webkit-transition: all 150ms linear;
            transition: all 150ms linear;
        }
        .dropdown-menu li.active > a,
        .dropdown-menu li.active > a:hover,
        .dropdown-menu li.active > a:focus,
        .dropdown-menu li > a:hover,
        .dropdown-menu li > a:focus {
            //box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
            box-shadow: none;
            color: $brand-link;
            //background: $brand-light;
            background: none;
        }
    }
    .navbar-nav > li{
        @media (min-width: 768px) and (max-width: 991px) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .navbar-nav > li > a{
        @media (min-width: 768px) and (max-width: 991px) {
            font-size: 16px;
        }
    }
}
.site{
    color: $brand-dark;
    padding-top: 83px;
    background: white;
    .breadcrumbs-xl{
        background-color: $brand-dark;
        color: white;
        padding: 5px 0 10px;
        .breadcrumb{
            background: none;
            margin-bottom: 0;
            color: white;
            font-size: 15px;
            line-height: 1;
            a{
                color: white;
            }
        }
    }
    .text-intro{
        padding: 60px 0 40px;
        .text-title{
            font-size: 28px;
            font-weight: bold;
            margin: 0 0 20px;
            padding: 0;
        }
        p{
            font-size: 17px;
        }
        .intro-box{
            margin-top: 10px;
            padding: 10px 15px;
            border: 1px solid $brand-dark;
            font-style: italic;
            img{
                width: 150px;
                max-width: 100%;
            }
            a,
            p{
                font-size: 14px !important;
                //margin-bottom: 0;
            }
            p:not(:last-child){
                //margin-bottom: 10px;
            }
        }
    }
    .dashboard{
        background: #afbdd2;
        padding: 60px 0 40px;
        h2{
            font-size: 21px;
            font-weight: bold;
            margin: 0 0 20px;
            padding: 0;
        }
    }
    .footer{
        text-align: left;
        background: $brand-light;
        padding: 60px 0 20px;
        a{
            font-size: 15px;
            display: inline-block;
            text-decoration: underline;
            text-transform: none;
            color: $brand-link;
        }
        h2{
            font-size: 21px;
            font-weight: bold;
            margin: 0 0 20px;
            padding: 0;
        }
        .footer-nav{
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                display: block;
                float: none;
                a{
                    font-size: 15px;
                    display: inline-block;
                    text-decoration: none;
                    text-transform: none;
                    color: $brand-dark;
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .footer-copyright{
            padding: 30px 0 20px;
            .copyright{
                margin: 0;
                padding: 0;
                font-style: italic;
                font-size: 13px;
            }
        }
    }
    .team-home{
        padding: 60px 0 40px;
        h2{
            font-size: 21px;
            font-weight: bold;
            margin: 0 0 30px;
            padding: 0;
        }
    }
    .news-home{
        background: #eee;
        padding: 60px 0 20px;
        h2{
            font-size: 21px;
            font-weight: bold;
            margin: 0 0 30px;
            padding: 0;
        }
        .image-list article a{
            background: none !important;
        }
    }
    .members-list{
        padding: 0;
        margin: 0 0 30px;
        list-style: none;
        font-size: 15px;
        position: relative;
        h2{
            font-size: 21px;
            font-weight: bold;
            margin: 0 0 20px;
            padding: 0;
        }
        .member{
            margin-bottom: 20px;
            a{
                display: block;
                overflow: hidden;
                font-weight: bold;
                padding: 4px;
                border-radius: 5px;
                -webkit-transition: all 150ms linear;
                transition: all 150ms linear;
                border-top-left-radius: 45px;
                border-bottom-left-radius: 45px;
                i{
                    color: black;
                    font-weight: normal;
                }
                .image-round{
                    background-color: #ccc;
                    float: left;
                    margin-right: 10px;
                    width: 64px;
                    height: 64px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    border-radius: 45px;
                }
                span{
                    display: inline-block;
                    padding-top: 10px;
                }
            }
            a.active,
            a:hover{
                color: $brand-link;
                background: #ddd;
                i{
                    color: black;
                }
            }
        }
    }
    .readmore{
        color: $brand-link;
        font-size: 15px;
        font-weight: bold;
    }
    .member-detail{
        .member-detail-header{
            .image-round{
                background-color: #ccc;
                float: left;
                margin-right: 20px;
                width: 64px;
                height: 64px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                border-radius: 45px;
            }
        }
        a{
            color: $brand-link;
            text-decoration: underline;
        }
    }
    .text-link-list{
        padding: 0;
        margin: 0 0 20px;
        list-style: none;
        font-size: 15px;
        position: relative;
        li{
            padding-left: 20px;
            margin-bottom: 10px;
            a{
                //text-decoration: underline;
                color: $brand-dark;
            }
            a:hover{
                color: $brand-link;
            }
            .research_home{
                color: $brand-link;
                font-size: 15px;
                padding-top: 5px;
                display: inline-block;
            }
        }
        li:before {
            position: absolute;
            left: 0;
            content: "\f0da";
            font-family: FontAwesome;
            display: inline-block;
            color: $brand-link;
        }
        li.pdf:before {
            content: "\f1c1";
        }
        li.word:before {
            content: "\f1c2";
        }
        li.external:before,
        li.text:before{
            content: "\f0f6";
        }
        li.event-icon:before{
            content: "\f073";
        }
        li.active a{
            color: $brand-link;
            font-weight: bold;
        }
        &.researchList{
            li{
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid $brand-link;
            }
            h2{
                font-size: 15px;
                font-weight: bold;
                padding: 0;
                margin: 0;
                color: $brand-link;
                padding-top: 2px;
            }
            a{
                font-weight: bold;
                color: $brand-link;
            }
            .article-date{
                margin: 5px 0;
                font-style: italic;
                font-weight: bold;
            }
        }
    }
    .image-list{
        //padding: 0;
        //margin: 0 0 20px;
        //list-style: none;
        font-size: 15px;
        article{
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
            .article-date{
                font-style: italic;
                display: inline-block;
                margin-bottom: 5px;
            }
            .article-title{
                font-weight: bold;
            }
            a{
                overflow: hidden;
                background-color: white;
                display: block;
                color: $brand-dark;
                -webkit-transition:all 0.25s ease-in-out;
        		-moz-transition:all 0.25s ease-in-out;
        		-o-transition:all 0.25s ease-in-out;
        		-ms-transition:all 0.25s ease-in-out;
        		transition:all 0.25s ease-in-out;
        		-webkit-transform-style: preserve-3d;
            }
            a:hover{
                //color: $brand-link;
            }
            &:hover{
                a{
                    //background-color: #eee;
                }
            }
            .zoom-text{
                position: relative;
                overflow: hidden;
                //left: 0;
                //right: 0;
                top: 0px;
                padding-bottom: 40px;
                //color: white;
                //font-size: 14px;
                //padding: 0px 8px;
                //padding-bottom: 40px;
                //line-height: 1.8em;
                //margin: 0;
                //text-transform: uppercase;
                -webkit-transition:all 0.25s ease-in-out;
        		-moz-transition:all 0.25s ease-in-out;
        		-o-transition:all 0.25s ease-in-out;
        		-ms-transition:all 0.25s ease-in-out;
        		transition:all 0.25s ease-in-out;
        		-webkit-transform-style: preserve-3d;
            }
            &:hover .zoom-text,
            &:focus .zoom-text{
                //padding-bottom: 40px;
                transform: translateY(-5px);
        		-webkit-transform:translateY(-5px);
        		-moz-transform:translateY(-5px);
        		-ms-transform:translateY(-5px);
        		-o-transform:translateY(-5px);
        		.zoom-readmore{
            		//bottom: 0px;
            		//left: 8px;
            		//font-size: .8em;
            		i{
                		//font-size: 1.2em;
            		}
        		}
            }
            .zoom-readmore{
                color: $brand-link;
        		position: absolute;
        		bottom: -20px;
        		font-weight: bold;
        		-webkit-transition:all 0.25s ease-in-out;
        		-moz-transition:all 0.25s ease-in-out;
        		-o-transition:all 0.25s ease-in-out;
        		-ms-transition:all 0.25s ease-in-out;
        		transition:all 0.25s ease-in-out;
        		-webkit-transform-style: preserve-3d;
        		i{
            		//font-size: 1.2em;
        		}
    		}
    		&:hover .zoom-readmore,
            &:focus .zoom-readmore{
                bottom: 10px;
                i{
                    //font-size: 1.2em;
                }
            }

            .image-landscape{
                //background-color: #ccc;
                //background-size: cover;
                //background-repeat: no-repeat;
                //background-position: 50% 50%;
                //border-radius: 5px;
                //padding: 50% 0 0;
                //margin: 0 0 20px;
            }
        }
    }
    .zoom-parent{
        display: block;
        position: relative;
        padding: 56.25% 0 0 0;
        margin-bottom: 15px;
        overflow: hidden;
        .zoom-child{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: #ccc;
            background-repeat: no-repeat;
            background-position: center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            transition: all .9s;
        }
        &:hover .zoom-child,
        &:focus .zoom-child {
            transform: scale(1.1);
        }
    }
    .social-sharing{
        a{
            line-height: 1;
            padding: 8px 12px;
            display: inline-block;
            background: $brand-link;
            color: white;
            border-radius: 4px;
            margin-right: 2px;
            text-decoration: none;
            font-size: .7em;
            text-transform: uppercase;
            i{
                font-size: 1.3em;
            }
        }
    }
    .year-filter{
        list-style: none;
        padding: 0;
        font-size: 15px;
        li{
            display: block;
            float: none;
            a{
                color: $brand-dark;
                text-decoration: underline;
            }
        }
    }
    .module-nav{
        overflow: hidden;
        padding-top: 30px;
        a.mod-nav-item{
            text-decoration: none;
            font-weight: bold;
            //border-bottom: 1px solid $brand-link;
            //background: $brand-dark;
            //text-decoration: none;
            //text-transform: none;
            i{
                //margin: 0 10px;
            }
        }
        .prev-item{
            float: left;
        }
        .next-item{
            float: right;
        }
    }
    .pagination > li > a,
    .pagination > li > span {
      border: 0;
      border-radius: 0px !important;
      -webkit-transition: all .3s;
      transition: all .3s;
      padding: 0px 11px;
      margin: 0 3px;
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      color: #999999;
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
      //background: transparent;
      box-shadow: none;
    }
    .pagination > li.active > a,
    .pagination > li > a:hover,
    .pagination > li > a:focus,
    .pagination > li > span:hover,
    .pagination > li > span:focus {
      color: white;
      background: $brand-dark;
    }
}
.list-page{
    padding: 60px 0 40px;
    h1,
    h2{
        font-size: 28px;
        font-weight: bold;
        margin: 0 0 30px;
        padding: 0;
    }
    .module-description{
        padding-bottom: 30px;
    }
}
.article-detail,
.text-page{
    padding: 60px 0 40px;
    h2{
        font-size: 21px;
        font-weight: bold;
        margin: 0 0 0px;
        padding: 0;
    }
    h2.sitebar-title,
    h1{
        font-size: 28px;
        font-weight: bold;
        margin: 0 0 30px;
        padding: 0;
    }
    a{
        color: $brand-link;
        text-decoration: underline;
    }
    p.member-role{
        margin-top: -25px;
        margin-bottom: 35px;
        font-size: 21px;
        font-style: italic;
    }
    .article-toolbar{
        font-style: italic;
        padding: 0 0 10px;
    }
    .text-intro{
        font-size: 15px;
        font-weight: bold;
        padding: 0;
    }
    p{
        margin-bottom: 30px;
    }
    .dutch-nav{
        padding: 30px 0;
        margin: 0;
        list-style: none;
        position: relative;
        li{
            padding-left: 20px;
            margin-bottom: 10px;
        }
        li:before {
            position: absolute;
            left: 0;
            content: "\f0da";
            font-family: FontAwesome;
            display: inline-block;
            color: $brand-link;
        }
    }
}