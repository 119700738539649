.after-parallax{
    position: relative;
    z-index: 1010;
}
.parallax-window{
    min-height: 150px;
    background: transparent;
}
.parallax-intro{
    padding-bottom: 40px;
}