// colors override
$brand-default:         #cecece !default;
$brand-primary:         black !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

$black-color:           #000000 !default;

$brand-dark: #002b54 !default;
$brand-light: #afbdd2 !default;
$brand-link: #860021 !default;

/*$gm-blue: #1B9ED5;
$gm-green: #4D9B25;
$gm-orange: #EC7C13;
$gm-yellow: #C1AD0C;
$gm-marker: #FFF431;*/

$gm-blue: #00BCE2;
$gm-green: #56AA1C;
$gm-orange: #D88C02;
$gm-yellow: #C4BA00;
$gm-marker: #E8DD21;
$gm-gray: #eeeeee;
$gm-black: #000000;

$opacity: .75;
$gm-blue-trans: rgba($gm-blue, $opacity);
$gm-green-trans: rgba($gm-green, $opacity);
$gm-orange-trans: rgba($gm-orange, $opacity);
$gm-yellow-trans: rgba($gm-yellow, $opacity);
$gm-marker-trans: rgba($gm-marker, $opacity);
$gm-gray-trans: rgba($gm-gray, $opacity);

$text-light: #ffffff !default;
$text-dark: #000000 !default;

$bg-light: #ffffff !default;
$bg-dark: #000000 !default;

$opacity2: .4;
$bg-light-transparent: rgba($bg-light, $opacity2);
$bg-dark-transparent: rgba($bg-dark, $opacity2);

// title bg
$bg-span-padding: 0; // 0.2em;
$bg-span-plus: 15px; // 17px
$bg-span-min: -15px; // -17px;
$bg-line-height: 1.5em; // 1.8em;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Merriweather', serif !default;
$font-family-serif:       'Merriweather', serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:                 3.8em !default;
$font-size-h2:                 2.6em !default;
$font-size-h3:                 1.825em !default;
$font-size-h4:                 1.3em !default;
$font-size-h5:                 1.25em !default;
$font-size-h6:                 0.9em !default;
$font-paragraph:               16px !default;
$font-size-navbar:             16px !default;
$font-size-small:              12px !default;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    500 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;

$title-font: $font-family-serif;
$body-font: $font-family-sans-serif;