// Fonts
//@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans|Material+Icons);

// Variables brand
@import "site/variables";
@import "site/mixins";

// Site bootstrap variables override
//@import "bootstrap.variables.site";

// Bootstrap
//@import "../libs/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../css/bootstrap.min";

//@import "../css/fonts";

// Font Awesome
@import "../libs/font-awesome-sass/font-awesome";

// Material
@import "material-kit";

// Plugins
@import "../libs/lightbox-master/dist/ekko-lightbox.min";
@import "hover/hover";

// Wonderfull generic
@import "site/site";
//@import "site/header";
//@import "site/generic";
//@import "site/global";
//@import "site/helpers";
//@import "site/footer";

// Wonderfull components
@import "site/components/slider";
@import "site/components/parallax";
//@import "site/components/page-header-detail";
//@import "site/components/cards";
//@import "site/components/lightbox";

// Wonderfull page types
//@import "site/page-types/team";

.swerk-notifcatie{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    overflow: hidden;
    p{
        margin-bottom: 0;
        line-height: 32px;
    }
    .swerk-not-title{
        float: left;
        margin-right: 20px;
        font-size: 32px;
    }
}
